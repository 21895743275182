import { useTranslation } from 'next-i18next';
import { Heading, Icon, Stack, Text, Container } from '@carvertical/ui';
import cx from 'classnames';
import type { ParseKeys } from 'i18next';
import ReportCheckIllustration from '../assets/report-check.svg';
import { Section } from './Section';
import styles from './ReasonsSection.module.scss';

type ReasonsSectionProps = {
  additionalContent?: React.ReactNode;
  reverse?: boolean;
};

const REASON_KEYS: ParseKeys<'home'>[] = [
  'reasonsSection.reasons.mileage',
  'reasonsSection.reasons.accidents',
  'reasonsSection.reasons.history',
];

const ReasonsSection = ({ additionalContent, reverse }: ReasonsSectionProps) => {
  const { t } = useTranslation('home');

  return (
    <Section>
      <Container>
        <Stack gap={{ mobileUp: 8, tabletPortraitUp: 15 }}>
          <div className={cx(styles.contentWrapper, reverse && styles.reverse)}>
            <Heading as="h2" variant="m" className={styles.title}>
              {t('reasonsSection.title')}
            </Heading>

            <div className={styles.illustrationWrapper}>
              <ReportCheckIllustration aria-hidden className={styles.illustration} />
            </div>

            <Stack as="ul" className={styles.reasons} gap={3}>
              {REASON_KEYS.map((key) => (
                <Stack className={styles.reason} key={key} gap={2} type="horizontal" as="li">
                  <div className={styles.iconWrapper}>
                    <Icon name="check-bold" size="xs" className={styles.checkIcon} />
                  </div>

                  <Text>{t(key)}</Text>
                </Stack>
              ))}
            </Stack>
          </div>

          {additionalContent && additionalContent}
        </Stack>
      </Container>
    </Section>
  );
};

export { ReasonsSection };
