import { castArray } from 'lodash';
import { Trans, useTranslation } from 'next-i18next';
import { Container, Grid, Heading, Stack, Text } from '@carvertical/ui';
import type { ReactNode } from 'react';
import { FEATURES } from 'constants/features';
import UnderlinedText from 'components/common/UnderlinedText';
import type { ThemeColor } from 'types/color';
import { Section } from './Section';
import styles from './FeaturesSection.module.scss';

type Variant = 'white' | 'lightBlue';
type DescriptionVariant = 'default' | 'moto' | 'universal';

type FeaturesSectionProps = {
  descriptionVariant?: DescriptionVariant;
  exclude?: string | string[];
  id?: string;
  subtitle?: string | ReactNode;
  title?: string | ReactNode;
  titleClassName?: string;
  variant?: Variant;
};

const UNDERLINE_COLOR_BY_VARIANT: Record<Variant, 'accent' | 'primary'> = {
  white: 'accent',
  lightBlue: 'primary',
};

const COLOR_BY_VARIANT: Record<Variant, Extract<ThemeColor, 'colorWhite' | 'colorBlue200'>> = {
  white: 'colorWhite',
  lightBlue: 'colorBlue200',
};

const FeaturesSection = ({
  descriptionVariant = 'default',
  exclude,
  id,
  subtitle,
  title,
  titleClassName,
  variant = 'white',
}: FeaturesSectionProps) => {
  const { t } = useTranslation(['home', 'common']);

  return (
    <Section color={COLOR_BY_VARIANT[variant]} id={id}>
      <Container>
        <Stack gap={{ mobileUp: 5, desktopUp: 8 }}>
          <Stack className={styles.title} gap={2}>
            <Heading variant="l" as="h2" className={titleClassName}>
              {title || (
                <Trans i18nKey="checksSection.title" t={t}>
                  What we
                  <UnderlinedText color={UNDERLINE_COLOR_BY_VARIANT[variant]}>
                    check
                  </UnderlinedText>{' '}
                  for when preparing a report
                </Trans>
              )}
            </Heading>

            <Text variant="l">{subtitle || t('checksSection.subtitle')}</Text>
          </Stack>

          <Grid
            className={styles.list}
            columnCount={{
              mobileUp: 1,
              tabletPortraitUp: 2,
              desktopUp: 3,
            }}
            rowGap={{
              mobileUp: 5,
              tabletPortraitUp: 8,
            }}
            columnGap={{
              mobileUp: 3,
              tabletPortraitUp: 6,
              desktopUp: 8,
            }}
          >
            {FEATURES.filter(
              ({ id: featureId }) => !exclude || !castArray(exclude).includes(featureId),
            ).map(
              ({ Icon, descriptionKey, motoDescriptionKey, titleKey, universalDescriptionKey }) => {
                const key = {
                  default: descriptionKey,
                  moto: motoDescriptionKey,
                  universal: universalDescriptionKey,
                }[descriptionVariant];

                return (
                  <Stack as="article" key={titleKey}>
                    {Icon && <Icon className={styles.icon} />}

                    <Stack gap={2}>
                      <Heading variant="s" as="h3">
                        {t(titleKey)}
                      </Heading>

                      {key && (
                        <Text variant="m" as="p">
                          {t(key)}
                        </Text>
                      )}
                    </Stack>
                  </Stack>
                );
              },
            )}
          </Grid>
        </Stack>
      </Container>
    </Section>
  );
};

export { FeaturesSection };
export type { FeaturesSectionProps };
