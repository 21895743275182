import { Icon } from '@carvertical/ui';
import cx from 'classnames';
import styles from './ScrollIndicator.module.scss';

type ScrollIndicatorProps = {
  className?: string;
};

const ScrollIndicator = ({ className }: ScrollIndicatorProps) => (
  <div className={cx(styles.root, className)}>
    <Icon name="arrow-down" size="m" className={styles.icon} />
  </div>
);

export { ScrollIndicator };
