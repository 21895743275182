import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './CloseButton.module.scss';

const CloseButton = ({ className, onClick, ...otherProps }) => (
  <button
    className={classnames(styles.root, className)}
    type="button"
    onClick={onClick}
    {...otherProps}
  />
);

CloseButton.propTypes = {
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CloseButton;
