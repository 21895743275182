import { noop } from 'lodash';
import cx from 'classnames';
import { type PropsWithChildren, type ReactNode, useEffect, useState } from 'react';
import BaseModal from 'react-aria-modal';
import { useTranslation } from 'next-i18next';
import { Button } from 'components/common/ui/Button';
import CloseButton from 'components/common/CloseButton';
import styles from './Modal.module.scss';

type ModalProps = {
  className?: string;
  variant?: 'simple' | 'ok' | 'confirm' | 'wall' | 'shell';
  title?: string;
  text?: string;
  actions?: ReactNode;
  maxWidth?: number;
  verticallyCenter?: boolean;
  closeInside?: boolean;
  focusDialog?: boolean;
  hasCloseButton?: boolean;
  mobileFullscreen?: boolean;
  onClose?: (type: 'cancel' | 'confirm') => void;
  confirmTestId?: string;
};

const OPEN_CLS = 'Modal--open';
const MAX_WIDTH = 475;
const THIRD_PARTY_MODALS = ['#wisepops-root', '._hj-widget-container'];

const open = () => {
  const htmlElement = document.documentElement;

  htmlElement.classList.add(OPEN_CLS);
};

const close = () => {
  document.documentElement.classList.remove(OPEN_CLS);
};

const hasThirdPartyModals = () => !!document.querySelectorAll(THIRD_PARTY_MODALS.join(', ')).length;

/**
 * @deprecated Use Modal from `@carvertical/ui` instead.
 */
const Modal = ({
  actions,
  children,
  className,
  closeInside,
  confirmTestId,
  focusDialog,
  hasCloseButton = true,
  maxWidth = MAX_WIDTH,
  mobileFullscreen,
  onClose = noop,
  text,
  title,
  variant = 'simple',
  verticallyCenter = true,
}: PropsWithChildren<ModalProps>) => {
  const { t } = useTranslation();
  const [paused, setPaused] = useState(false);

  const closeByType = (type: 'cancel' | 'confirm') => {
    close();
    onClose(type);
  };

  const cancel = () => closeByType('cancel');
  const confirm = () => closeByType('confirm');

  useEffect(() => {
    setPaused(hasThirdPartyModals());

    const observer = new MutationObserver(() => {
      setPaused(hasThirdPartyModals());
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
      close();
    };
  }, []);

  const closable = variant !== 'wall';

  return (
    <BaseModal
      titleText={title || 'Modal'}
      verticallyCenter={verticallyCenter}
      underlayClickExits={closable}
      focusTrapPaused={paused}
      escapeExits={closable}
      focusDialog={!closable || focusDialog}
      focusTrapOptions={{ fallbackFocus: document?.body }}
      dialogClass={cx(styles.dialog, mobileFullscreen && styles.mobileFullscreen)}
      dialogStyle={{ maxWidth }}
      underlayClass={cx(styles.underlay, mobileFullscreen && styles.mobileFullscreen)}
      scrollDisabled
      onExit={cancel}
      onEnter={open}
    >
      <div
        className={cx(
          styles.root,
          styles[variant],
          mobileFullscreen && styles.mobileFullscreen,
          closeInside && styles.closeInside,
          className,
        )}
      >
        {children || (
          <div className={styles.message}>
            {title && <h1 className={styles.title}>{title}</h1>}
            {text && <p className={styles.text}>{text}</p>}
          </div>
        )}
        {variant === 'ok' && (
          <div className={styles.actions}>
            <Button variant="primary" onClick={cancel}>
              {t('general.okAction')}
            </Button>
          </div>
        )}
        {variant === 'confirm' && (
          <div className={styles.actions}>
            <Button variant="primary" onClick={confirm} data-testid={confirmTestId}>
              {t('general.confirmAction')}
            </Button>
            <Button variant="outline" onClick={cancel}>
              {t('general.cancelAction')}
            </Button>
          </div>
        )}
        {actions && <div className={styles.actions}>{actions}</div>}
        {hasCloseButton && closable && (
          <CloseButton
            className={styles.closeButton}
            onClick={cancel}
            aria-label={t('general.closeAction')}
          />
        )}
      </div>
    </BaseModal>
  );
};

export { Modal };
export type { ModalProps };
