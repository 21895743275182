import cx from 'classnames';
import Image from 'next/image';
import { Container } from '@carvertical/ui';
import { TrustBanner } from 'components/landings/common/TrustBanner';
import avatarsUrl from 'assets/images/trustpilot/trustpilot-avatars-clean.png';
import { ScrollIndicator } from './ScrollIndicator';
import styles from './HorizontalTrustBanner.module.scss';

type HorizontalTrustBannerProps = {
  className?: string;
  color?: 'white' | 'grey';
};

const HorizontalTrustBanner = ({ className, color = 'white' }: HorizontalTrustBannerProps) => (
  <aside className={cx(styles.root, styles[color], className)}>
    <Container>
      <div className={styles.wrapper}>
        <div className={styles.trustWrapper}>
          <Image
            src={avatarsUrl}
            alt="Trustpilot clients"
            width={87}
            height={32}
            className={styles.image}
          />
          <TrustBanner
            textClassName={styles.trustText}
            className={styles.trustBanner}
            logoClassName={styles.logo}
            listClassName={styles.logoList}
          />
        </div>
        <ScrollIndicator />
      </div>
    </Container>
  </aside>
);

export { HorizontalTrustBanner };
